import React from 'react';
import styled from 'styled-components';
import { MEDIUM } from '../../breakpoints';

export const RoundedSquare = styled.div`
  width: ${({ size }) => (typeof size === 'object' ? size.sm : size)};
  height: ${({ size }) => (typeof size === 'object' ? size.sm : size)};
  background: ${({ theme, bgColor }) =>
    typeof bgColor === 'function' ? bgColor(theme) : bgColor || 'transparent'};
  border-radius: 15%;
  transform: ${({ rotation = '30deg' }) => `rotate(${rotation})`};

  @media (min-width: ${MEDIUM}) {
    width: ${({ size }) => (typeof size === 'object' ? size.md : size)};
    height: ${({ size }) => (typeof size === 'object' ? size.md : size)};
  }
`;

export const SmallRoundedSquare = props => (
  <RoundedSquare
    size={{ sm: '12px', md: '28px' }}
    rotation="30deg"
    {...props}
  />
);

export const BigRoundedSquare = props => (
  <RoundedSquare
    size={{ sm: '192px', md: '390px' }}
    rotation="-60deg"
    {...props}
  ></RoundedSquare>
);
