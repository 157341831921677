import styled from 'styled-components';
import { SMALL, MEDIUM, LARGE } from '../breakpoints';

export const Grid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;

  > * {
    max-width: 100%;
  }

  @media (min-width: ${SMALL}) {
    grid-template-columns: repeat(${({ columns }) => columns || '8'}, 1fr);
  }

  @media (min-width: ${MEDIUM}) {
    grid-template-columns: repeat(${({ columns }) => columns || '8'}, 102px);
  }

  @media (min-width: ${LARGE}) {
    grid-template-columns: repeat(${({ columns }) => columns || '8'}, 122px);
  }

  @media (min-width: ${SMALL}) {
    > .grid-span-md-1 {
      grid-column: span 1 / span 1;
    }
    > .grid-span-md-2 {
      grid-column: span 2 / span 2;
    }
    > .grid-span-md-3 {
      grid-column: span 3 / span 3;
    }
    > .grid-span-md-4 {
      grid-column: span 4 / span 4;
    }
    > .grid-span-md-5 {
      grid-column: span 5 / span 5;
    }
    > .grid-span-md-6 {
      grid-column: span 6 / span 6;
    }
    > .grid-span-md-7 {
      grid-column: span 7 / span 7;
    }
    > .grid-span-md-8 {
      grid-column: span 8 / span 8;
    }
  }

  @media (min-width: ${MEDIUM}) {
    > .grid-span-lg-1 {
      grid-column: span 1 / span 1;
    }
    > .grid-span-lg-2 {
      grid-column: span 2 / span 2;
    }
    > .grid-span-lg-3 {
      grid-column: span 3 / span 3;
    }
    > .grid-span-lg-4 {
      grid-column: span 4 / span 4;
    }
    > .grid-span-lg-5 {
      grid-column: span 5 / span 5;
    }
    > .grid-span-lg-6 {
      grid-column: span 6 / span 6;
    }
    > .grid-span-lg-7 {
      grid-column: span 7 / span 7;
    }
    > .grid-span-lg-8 {
      grid-column: span 8 / span 8;
    }
  }

  @media (min-width: ${LARGE}) {
    > .grid-span-xl-1 {
      grid-column: span 1 / span 1;
    }
    > .grid-span-xl-2 {
      grid-column: span 2 / span 2;
    }
    > .grid-span-xl-3 {
      grid-column: span 3 / span 3;
    }
    > .grid-span-xl-4 {
      grid-column: span 4 / span 4;
    }
    > .grid-span-xl-5 {
      grid-column: span 5 / span 5;
    }
    > .grid-span-xl-6 {
      grid-column: span 6 / span 6;
    }
    > .grid-span-xl-7 {
      grid-column: span 7 / span 7;
    }
    > .grid-span-xl-8 {
      grid-column: span 8 / span 8;
    }
  }

  @media (max-width: ${SMALL}) {
    > .grid-span-sm-1 {
      grid-column: span 1 / span 1;
    }
    > .grid-span-sm-2 {
      grid-column: span 2 / span 2;
    }
  }
`;

export const FluidGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;

  > * {
    max-width: 100%;
  }

  @media (min-width: ${SMALL}) {
    grid-template-columns: repeat(${({ columns }) => columns || '8'}, 1fr);
  }

  @media (min-width: ${SMALL}) {
    > * {
      grid-column: span 8 / span 8;
    }
    > .grid-span-md-1 {
      grid-column: span 1 / span 1;
    }
    > .grid-span-md-2 {
      grid-column: span 2 / span 2;
    }
    > .grid-span-md-3 {
      grid-column: span 3 / span 3;
    }
    > .grid-span-md-4 {
      grid-column: span 4 / span 4;
    }
    > .grid-span-md-5 {
      grid-column: span 5 / span 5;
    }
    > .grid-span-md-6 {
      grid-column: span 6 / span 6;
    }
    > .grid-span-md-7 {
      grid-column: span 7 / span 7;
    }
    > .grid-span-md-8 {
      grid-column: span 8 / span 8;
    }
  }

  @media (max-width: ${SMALL}) {
    > * {
      grid-column: span 2 / span 2;
    }

    > .grid-span-sm-1 {
      grid-column: span 1 / span 1;
    }

    > .grid-span-sm-2 {
      grid-column: span 2 / span 2;
    }
  }
`;
